@import "../../base.scss";

.nav-main-mobile-items {
//     .row-logo {
//         .col {
//             flex-basis: auto;
//             flex-grow: initial;
//             @extend %flex;
//             // align-items: center;
//             min-height: $header-height;
//             // padding-top: 15px;
//             padding-bottom: 85px;
//             &.nav-main-logo {
//                 padding-top:0;
//                 padding-bottom:10px;
//                 img {
//                     max-height: 45px;
//                     height: 100%;
//                     width: auto;
//                     max-width: none;
//                     display: block;
//                 }
//             }
//         }
//     }

    .row {
        &.row-1 {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            .col {
                flex-direction: column;
                text-align: left;
                width: 100%;
            }
        }
        &.row-2 {
            z-index: 10;
            position: absolute;
            width: 100%;
            left: 16px;
            // right: 0;
            background-color: #15dd95;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            display: flex;
            justify-content: center;
            bottom: 0;
        }
    }
}
