@import "../../base";

.aboutus {
    .section.section-trustus {
        @media all and (max-width: $bp-mobile-sm) {
            .row.boxes-wrap-m {
                padding-top: 40px;
                flex-direction: column;
                > .col {
                    flex-basis: auto;
                    flex-grow: initial;
                }
                .trust-m {
                    position: relative;
                    min-height: 60px;
                    margin-bottom: 25px;
                    .trust-box {
                        position: absolute;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        background-color: #ffffff;
                        min-height: 60px;
                        justify-content: flex-start;
                        align-items: center;
                        @include box-shadow($box-shadow);
                        border-radius: $border-radius-sm;
                        padding:10px 45px 10px 15px;
                        h4 {
                            font-size: 16px;
                            line-height: 1.2em;
                            padding: 0;
                            margin: 0;
                            text-transform: uppercase;
                        }
                        .icon {
                            width: 50px;
                            img {
                                display: block;
                                width:30px;
                            }
                        }
                        .plus {
                            cursor: pointer;
                            width: 36px;
                            height: 33px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            border-top-left-radius: $border-radius-sm;
                            border-bottom-right-radius: $border-radius-sm;
                            background-color: $color-2;
                            color: #ffffff;
                            img {
                                display: block;
                                width: 15px;
                            }
                        }
                    }

                    .trust-info {
                        position: relative;
                        background-color: $base-color;
                        color: #ffffff;
                        border-radius: $border-radius-sm;
                        > div {
                            padding:10px 15px;
                        }

                        h4 {
                            min-height: 40px;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            font-size: 16px;
                            padding-bottom: 0;
                            margin-bottom: 15px;
                            text-transform: uppercase;
                            padding-right:40px;
                            .icon {
                                display: block;
                                width: 30px;
                                margin-right:15px;
                            }
                        }

                        .minus {
                            cursor: pointer;
                            z-index: 1;
                            width: 36px;
                            height: 33px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            top: 0;
                            right: 0;
                            border-top-right-radius: $border-radius-sm;
                            border-bottom-left-radius: $border-radius-sm;
                            background-color: #ffffff;
                            color: $color-5;
                            img {
                                display: block;
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
