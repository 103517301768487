@import "./base.scss";

/* BASE */
body {
  color: #0e1833;
  font-size: $font-size;
  font-family: $font-family-regular;
  background-color: $color-3;
  @media all and (max-width: $bp-responsive) {
    font-size: 95%;
  }
  @media all and (max-width: $bp-mobile) {
    font-size: 16px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1 {
  position: relative;
  display: inline-block;
  padding-bottom: 20px;
  font-size: $font-size-xxxxl;
  font-family: $font-family-bold;
  font-weight: normal;
  margin-bottom: 50px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70%;
    height: 1px;
    overflow: hidden;
    border-bottom: 4px solid $color-2;
  }
}
h2 {
  padding-bottom: $sidespace-sm;
  font-size: $font-size-xxxl;
  font-family: $font-family-bold;
  font-weight: normal;
}
h3 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-family: $font-family-bold;
  font-weight: normal;
}
h4 {
  font-family: $font-family-bold;
  font-size: $font-size-md;
  font-weight: normal;
}
h5 {
  padding-bottom: 25px;
  font-family: $font-family-regular;
  font-size: $font-size-sm;
  font-weight: normal;
}

a {
  color: $link-color;
  @include transition($animation);
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}

/*special hover*/
a.hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  @include transition($animation);
  &:before {
    content: "";
    position: absolute;
    overflow: hidden;
    margin: auto;
    height: 1px;
    bottom: -3px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    opacity: 0;
    @include transition($animation);
  }
  &:hover {
    text-decoration: none;
    @include transition($animation);
    &:before {
      width: 100%;
      opacity: 1;
    }
  }
}
/*end special hover*/

p {
  padding-bottom: $sidespace;
  line-height: 1.3em;
}
p:last-child {
  padding-bottom: 0;
}

ul {
  list-style: none;
}
.ul {
  padding-bottom: $sidespace-lg;
  margin-left: 20px;
  li {
    padding-bottom: $sidespace-sm;
    &:before {
      content: "";
      background-color: $base-color;
      font-weight: bold;
      display: inline-block;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      margin-left: -20px;
      margin-right: 10px;
    }
  }
}

ol {
  list-style-position: inside;
}

small {
  font-family: $font-family-medium;
  font-size: $font-size-xs;
}

b,
.bold {
  font-family: $font-family-bold;
}
.font-medium {
  font-family: $font-family-medium;
}
.font-regular {
  font-family: $font-family-regular;
}
.font-black {
  font-family: $font-family-black;
}
.font-light {
  font-family: $font-family-light;
}
.font-thin {
  font-family: $font-family-thin;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.font-size-lg {
  font-size: $font-size-lg;
}
.font-size-sm {
  font-size: $font-size-sm;
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-justify {
  text-align: justify;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.align-space-around {
  display: flex;
  justify-content: space-around;
}
.align-space-between {
  display: flex;
  justify-content: space-between;
}

.space-bottom-lg {
  padding-bottom: $sidespace-lg;
}
.space-bottom-xl {
  padding-bottom: $sidespace-xl;
}
.space-bottom-xxxl {
  padding-bottom: $sidespace-xxxl;
}
// $sidespace-xs: 10px;
// $sidespace-sm: 15px;
// $sidespace: 25px;
// $sidespace-md: 30px;
// $sidespace-lg: 45px;
// $sidespace-xl: 50px;
// $sidespace-xxl: 65px;
// $sidespace-xxxl: 75px;

.base-color {
  color: $base-color;
}
.color-2 {
  color: $color-2;
}

.blue {
  color: $base-color;
}
.green {
  color: $color-2;
}
.lightblue {
  color: $color-7;
}

.uppercase {
  text-transform: uppercase;
}
/*end BASE*/

/*LAYOUT*/

.row {
  @extend %flex;
  margin-left: -15px;
  margin-right: -15px;
}
.col {
  @extend %col;
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  max-width: $container-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $sidespace-lg;
  padding-right: $sidespace-lg;

  @media all and (max-width: $bp-responsive) {
    padding-left: $sidespace-md;
    padding-right: $sidespace-md;
  }
  @media all and (max-width: $bp-tablet) {
    padding-left: $sidespace;
    padding-right: $sidespace;
  }
}

.section {
  position: relative;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.box {
  padding: 20px;
  background-color: $color-3;
  border-radius: 20px;
  @include box-shadow($box-shadow);
}
.box-img {
  border-radius: 20px;
  overflow: hidden;
  @include box-shadow($img-shadow);
  img {
    display: block;
    border-radius: 20px;
  }
}

// .bp-desktop {}
// .bp-mobile {}
/*end LAYOUT */

/* MAIN - ELEMENTS */

.rotate-chevron-tobottom {
  @include transform(rotate(90deg));
}

.link-back {
  font-family: $font-family-medium;
  @extend %flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 1em;
  a {
    img {
      margin-right: 5px;
    }
    text-decoration: none;
  }
}

.docs-list {
  position: relative;
  display: inline-block;
  max-width: 555px;
  > div {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.slick-arrow {
  background-color: $color-2;
  height: 36px;
  width: 36px;
  overflow: hidden;
  border-radius: 100%;
  top: 100%;

  @media all and (max-width: $bp-tablet) {
    top: 100%;
  }
  @include transform(translateX(50%));
  color: #ffffff;
  &:hover {
    @include transform(translateX(50%));
    color: #ffffff;
    background-color: $color-2;
  }
  &:focus {
    @include transform(translateX(50%));
    color: #ffffff;
    background-color: $color-2;
  }
  &.slick-prev {
    left: 45%;
    &:before {
      @media (max-width: $bp-tablet) {
        margin-left: 10px;
        margin-bottom: 5px;
      }
      display: block;
      font-size: 23px;
      line-height: 1px;
      content: "\0221F";
      @include transform(rotate(45deg));
      opacity: 1;
      margin-left: 5px;
    }
  }
  &.slick-next {
    right: 45%;
    &:before {
      @media (max-width: $bp-tablet) {
        margin-right: 10px;
        margin-top: 4px;
      }
      display: block;
      font-size: 23px;
      line-height: 1px;
      content: "\0221F";
      @include transform(rotate(225deg));
      opacity: 1;
      margin-right: 5px;
    }
  }

  @media all and (max-width: 1440px) {
    &.slick-prev {
      left: 43%;
    }
    &.slick-next {
      right: 43%;
    }
  }
  @media all and (max-width: $bp-tablet) {
    &.slick-prev {
      left: 30%;
    }
    &.slick-next {
      right: 43%;
    }
  }
}

.paging {
  margin: auto;
  text-align: center;
  > div {
    display: inline-block;
    padding: 0 2px;
  }

  button {
    font-family: $font-family-medium;
    font-size: $font-size-md;
    background: transparent;
    border: 0;
    text-align: center;
    min-width: 45px;
    height: 45px;
    color: $txt-color;
    border-radius: 100%;
    &.active {
      background-color: $color-2;
      color: #ffffff;
    }
  }
}
/*end MAIN ELEMENTS */


/* CSSTransition */
.dialog-enter {
  opacity: 0.01;
  // transform: scale(1.1);
}
.dialog-enter-active {
  opacity: 1;
  // transform: scale(1);
  transition: all 500ms;
}
.dialog-exit {
  opacity: 1;
  // transform: scale(1);
}
.dialog-exit-active {
  opacity: 0.01;
  // transform: scale(1.1);
  transition: all 500ms;
}
/*end CSSTransition*/