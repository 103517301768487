@import "../../base";

.faqs {
    @media all and (max-width: $bp-tablet-sm) {
        .faqs-list-m {
            width: 100%;
            > div {
                padding-bottom: 10px;

                .box-link {
                    margin-bottom: 10px;
                    .title {
                        font-size: 16px;
                        font-family: $font-family-bold;
                    }
                    &:before {
                        transform: rotate(135deg) translateY(10%) translateX(-85%);
                    }
                    &.active {
                        background-color:$base-color;
                        color:#ffffff;
                        .title {
                            color:#ffffff;
                        }
                        &:before {
                            transform: rotate(-45deg) translateY(20%) translateX(50%);
                        }
                    }
                }
                .box-link-info {
                    margin-bottom: 20px;
                    background-color: #ffffff;
                    @include box-shadow($box-link-shadow);
                    border-radius: $border-radius-sm;
                    padding: 10px;
                    font-size: 16px;
                    // .faqs-list-info {

                    // }
                    .faq-info {
                        border-bottom: 1px solid $color-9;
                        &:last-child {
                            border-bottom: 0;
                        }
                        .ul {
                            padding-bottom:25px;
                            &:last-child {
                                padding-bottom: 0;
                            }
                        }
                        .open,
                        .close {
                            padding: 15px 10px 15px 25px;
                            position: relative;
                            $animation: all 0.5s ease-in-out;
                            &:before {
                                content: "";
                                position: absolute;
                                top: 20px;
                                left: 5px;
                                background-color: $base-color;
                                font-weight: bold;
                                display: block;
                                border-radius: 100%;
                                width: 10px;
                                height: 10px;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                top: 20px;
                                right: 5px;
                                display:block;
                                width:10px;
                                height:10px;
                                border-left:2px solid $color-2;
                                border-bottom:2px solid $color-2;
                                transform: rotate(135deg);
                                $animation: all 0.5s ease-in-out;
                            }
                        }

                        .open {
                            &:after {
                                transform: rotate(-45deg);
                            }
                        }
                        > div:nth-child(2) > div {
                            padding: 10px 10px 25px 10px;
                        }
                    }
                }
            }
        }
    }
}
