@import "../../base";

.download-app-body {
    .header {
        .row {
            justify-content: flex-start;
            position: relative;
            text-align: center;
            .col {
                &.col-nav-main {
                    display: none;
                }
                &.col-headline {
                    display: inline-block;
                    position: absolute;
                    max-width: 400px;
                    top: 17px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    h2 {
                        margin: auto;
                        padding: 0;
                        text-transform: uppercase;
                        line-height: 1em;
                        font-size: 1.4em;
                    }
                    @media all and (max-width: 1000px) {
                        max-width: 320px;
                    }
                }
            }
        }
    }
}

.download-app {
    position: relative;

    + .footer {
        display: none;
    }
    .footer-download-app {
        min-height: 45px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: $base-color;
        .row {
            justify-content: center;
            align-items: center;
        }
        .col {
            flex-basis: auto;
            flex-grow: initial;
            padding: 10px 5%;
            text-align: center;
            a {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }

    .container-main {
        > .row {
            padding-top: calc(85px + 4vh);
            padding-bottom: calc(45px + 4vh);
            // padding-top: $header-topspace;
            // padding-bottom: $header-height;
            min-height: 100vh;
            align-items: center;
        }
    }

    .col-mockup {
        @extend %flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 70vh;
        padding-left: 5%;
        padding-right: 5%;
        background-image: url("/imgs/amarant-download-bg.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 88% 88%;
    }
    .phone-mockup {
        position: absolute;
        height: 100%;
        max-height: 700px;
        width: 100%;
        text-align: center;
        .phone-img {
            position: relative;
            height: 100%;
            margin: auto;
            img {
                display: block;
                height: 100%;
                width: auto;
                max-width: none;
                margin: auto;
            }
            .qr-code {
                z-index: 2;
                position: absolute;
                display: block;
                top: 55%;
                left: 0;
                right: 0;
                margin: auto;
                width: 30%;
                max-width:120px;
            }
        }
    }

    .appstore,
    .googleplay {
        ul {
            padding-left: 70px;
        }
        h3 {
            position: relative;
            text-transform: uppercase;
            padding-left: 70px;
            line-height: 1.2em;
            min-height: 60px;
            padding-bottom: 0;
            margin-bottom: 25px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 58px;
                height: 58px;
                border-top-left-radius: $border-radius-sm;
                border-bottom-right-radius: $border-radius-sm;
                @extend %flex;
                justify-content: center;
                align-items: center;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% auto;
                background-color: $color-2;
                @include box-shadow($box-shadow-2);
            }
            &.apple {
                &:before {
                    background-image: url("/icons/apple.svg");
                }
            }
            &.android {
                &:before {
                    background-image: url("/icons/android.svg");
                }
            }
        }

        @media all and (max-width: 1100px) {
            ul {
                padding-left: 0;
            }
        }

        @media all and (max-width: $bp-tablet) {
            h3 {
                font-size: 1em;
            }
        }
    }
}
