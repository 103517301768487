@import "../../base";

.aboutus {

  .section.section-trustus {
    .container {
      max-width: $container-width-2;
    }

    .row.boxes-wrap {
      padding-top:50px;
      position: relative;
      > .col {
        padding: 0;        
      }

      .trust {
        position: relative;
        margin:auto;
        background-color: $color-3;
        text-align: center;
        width: 245px;
        height: 245px;
        @extend %flex;
        align-items: center;
        justify-content: center;
        overflow: visible;
        padding: 15px;
        background-color: $color-3;
        border-radius: 20px;
        @include box-shadow($box-shadow);

        .plus {
          cursor: pointer;
          z-index: 1;
          width: 45px;
          height: 45px;
          @extend %flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          border-top-right-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
          background-color: $color-2;
          color: #ffffff;
          img {
            display: block;
          }
        }

        .trust-info {
          width: 0;
          opacity: 0;
          overflow:hidden;
          @include transition($animation);

          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: $base-color;
          color: #ffffff;
          z-index: 3;
          text-align: left;
          padding: 20px;
          border-radius: $border-radius;

          h4 {
            @extend %flex;
            align-items: center;
            text-transform: uppercase;
            padding-bottom: $sidespace-sm;
            .icon {
              padding-right: 15px;

              img {
                @media all and (max-width: $bp-tablet) {
                  width: 40px;
                  padding: 5px;
                  display: flex;
                  align-items: center;
                  // border: 1px solid red;
                }
                display: block;
              }
            }
          }
          .minus {
            cursor: pointer;
            z-index: 1;
            width: 45px;
            height: 45px;
            @extend %flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            border-top-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            background-color: #ffffff;
            color: $color-5;
            img {
              display: block;
            }
          }
        }
        .trust-box {
          position: relative;
          .icon {
            padding-bottom: 10px;
            img {
              display: block;
              margin: auto;
            }
          }
          h4 {
            padding: 0;
          }
        }
        &#trustus1 .trust-info,
        &#trustus3 .trust-info {
          left: 0;
          right: auto;
        }
        &#trustus2 .trust-info,
        &#trustus4 .trust-info {
          right: 0;
          left: auto;
        }

        &.trust-open {
          .trust-info {
            width: calc(241%);
            opacity: 1; 
            @include transition($animation);
          }
        }

      }

      @media all and (max-width: $bp-responsive) {
        .trust {
          width: 200px;
          height: 200px;
          .plus {
          }
          .trust-info {
          }
          .trust-box {
            position: relative;
            .icon {
              padding-bottom: 10px;
              img {
                display: block;
                margin: auto;
                width: 75px;
              }
            }
            h4 {
              padding: 0;
            }
          }

          &.trust-open {
            .trust-info {
              width: calc(255%);
            }
          }

        }
      }

      @media all and (max-width: $bp-tablet) {
        flex-wrap:wrap;
        max-width:600px;
        margin-left:auto;
        margin-right:auto;

        > .col {
          padding: 0;        
          flex-basis: auto;
          flex-grow: initial;
          width:50%;
          padding-bottom:40px;
        }

        .trust {
          .plus {
          }
          .trust-info {
          }
          .trust-box {
            position: relative;
            .icon {
              padding-bottom: 10px;
              img {
                display: block;
                margin: auto;
                width: 45px;
              }
            }
            h4 {
              padding: 0;
            }
          }

          &.trust-open {
            .trust-info {
              width: calc(255%);
            }
          }

        }
      }

     

    }
  }





}
